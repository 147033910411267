<template>
  <div class="header">
    <div class="title">Arrabona Racing Team - Telemetry</div>
    <nav class="nav">
      <router-link to="/dashboard" class="nav-item">Dashboard</router-link>
      <router-link to="/track" class="nav-item">Track</router-link>
      <router-link to="/diagram" class="nav-item">Diagrams</router-link>
    </nav>
    <img src="/img/ART_white_empty.png" class="logo" />
  </div>
</template>

<script setup></script>

<template>
    <div class="G-force" >
        <div class="circle --1">
            <div class="text top">3G</div>
            <div class="text right">3G</div>
            <div class="text bot">3G</div>
            <div class="text left">3G</div>
        </div>
        <div class="circle --2" />
        <div class="circle --3" />
        <div class="circle --4" />
        <div class="circle --5" />
        <div class="circle --6" >
          <div class="dot" :style="GforeStyle" />
        </div>
        <div class="scale" />
        <div class="scale v" />
    </div>
</template>

<script setup>
import { computed } from 'vue';
const props = defineProps({
  XCoord: {
    type: Number,
    required: true,
  },
  YCoord: {
    type: Number,
    required: true,
  }
});

const GforeStyle= computed(() => {
  if(props.GForce==1){
    return{
      left: '38.5px'
    };
  }
  if(props.GForce==-1){
    return{
      left: '38.5px'
    };
  }

});

</script>


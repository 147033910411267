<template>
  <div class="container dashboard">
    <div class="left">
      <div class="top">
        <Speedometer :speed="Vehicle_speed" :rpm="RPM" :gear="Gear" />
        <G-force  :XCoord="Acceleration_right"  :YCoord="Acceleration_forward" />
      </div>
      <div class="bottom">
        <Steering_wheel :steering_angle="Steering_angle" />
        <div>
          <Pedals :th_ped_pos="Throttle_position" :br_ped_pos="Front_brake_pressure" />
        </div>
        <div class="icons">
          <div>
            <span class="battery_volts_t">{{ Battery_voltage }}</span>
            <img src="/img/battery.png" alt="Battery" class="battery" />
          </div>
          <div>
            <span class="oil_temp_t">{{ Oil_preassure }}</span>
            <img src="/img/oil.png" alt="Oil" class="oil" />
          </div>
          <div>
            <span class="water_temp_t">{{ Cooleant_temp }}</span>
            <img src="/img/water.png" alt="Water" class="water" />
          </div>
          <div>
            <span class="pneu_press_t">{{ Pneu_pressure }}</span>
            <img src="/img/pressure.png" alt="Pressure" class="pressure" />
          </div>
          <div ></div>
        </div>
      </div>
    </div>
    <div class="right">
      <Car
        :fril_temp="Tyre_Temp_FR1"
        :frir_temp="Tyre_Temp_FR3"
        :frl_temp="Tyre_Temp_FR5"
        :frr_temp="Tyre_Temp_FR7"
        :flil_temp="Tyre_Temp_FL1"
        :flir_temp="Tyre_Temp_FL3"
        :fll_temp="Tyre_Temp_FL5"
        :flr_temp="Tyre_Temp_FL7"
        :rlil_temp="Tyre_Temp_RL1"
        :rlir_temp="Tyre_Temp_RL3"
        :rll_temp="Tyre_Temp_RL5"
        :rlr_temp="Tyre_Temp_RL7"
        :rril_temp="Tyre_Temp_RR1"
        :rrir_temp="Tyre_Temp_RR3"
        :rrl_temp="Tyre_Temp_RR5"
        :rrr_temp="Tyre_Temp_RR7"
        :drs_on="DRS_ON"
        :drs_off="DRS_OFF"
        :brakeTemp_FR="brake_temp_fr"
        :brakeTemp_RR="brake_temp_rr"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Car from '@/components/Car.vue';
import Speedometer from '@/components/Speedometer.vue';
import GForce from '@/components/GForce.vue';
import Pedals from '@/components/Pedals.vue';
import Steering_wheel from '@/components/Steering_wheel.vue';
import mqtt from 'mqtt';

const mqtt_url = process.env.VUE_APP_MQTT_URL;
const mqtt_pw = process.env.VUE_APP_MQTT_PW;

const RPM = ref(0);
const Gear = ref('N');
const Vehicle_speed= ref(0);
const Throttle_position = ref(0);
const Front_brake_pressure = ref(0);
const Battery_voltage = ref(0);
const DRS = ref(null);
const DRS_ON = ref(false);
const DRS_OFF = ref(false);
const Cooleant_temp = ref(0);
const Oil_preassure = ref(0);
const Pneu_pressure = ref(0);
const brake_temp_fr =ref(0)
const brake_temp_rr =ref(0)

// Tyre temperatures
// --front right
const Tyre_Temp_FR1 = ref(0);
const Tyre_Temp_FR2  = ref(0);
const Tyre_Temp_FR3  = ref(0);
const Tyre_Temp_FR4  = ref(0);
const Tyre_Temp_FR5  = ref(0);
const Tyre_Temp_FR6  = ref(0);
const Tyre_Temp_FR7  = ref(0);
const Tyre_Temp_FR8  = ref(0);
// --front left
const Tyre_Temp_FL1 = ref(0);
const Tyre_Temp_FL2  = ref(0);
const Tyre_Temp_FL3  = ref(0);
const Tyre_Temp_FL4  = ref(0);
const Tyre_Temp_FL5  = ref(0);
const Tyre_Temp_FL6  = ref(0);
const Tyre_Temp_FL7  = ref(0);
const Tyre_Temp_FL8  = ref(0);
// --rear left
const Tyre_Temp_RL1 = ref(0);
const Tyre_Temp_RL2  = ref(0);
const Tyre_Temp_RL3  = ref(0);
const Tyre_Temp_RL4  = ref(0);
const Tyre_Temp_RL5  = ref(0);
const Tyre_Temp_RL6  = ref(0);
const Tyre_Temp_RL7  = ref(0);
const Tyre_Temp_RL8  = ref(0);
// --rear right
const Tyre_Temp_RR1 = ref(0);
const Tyre_Temp_RR2  = ref(0);
const Tyre_Temp_RR3  = ref(0);
const Tyre_Temp_RR4  = ref(0);
const Tyre_Temp_RR5  = ref(0);
const Tyre_Temp_RR6  = ref(0);
const Tyre_Temp_RR7  = ref(0);
const Tyre_Temp_RR8  = ref(0);

const Steering_angle = ref(0);
const Acceleration_right = ref(0)
const Acceleration_forward = ref(0)

const setDRS = (drs_value) => {
  if(drs_value=="On"){
    drs_value=true
    if (drs_value == true) {
      DRS_ON.value = true;
      DRS_OFF.value = false;
    }
  }else if(drs_value=="Off"){
      DRS_ON.value = false;
      DRS_OFF.value = true;
  }
};

const establishMqttConnection = () => {
  const client = mqtt.connect(mqtt_url, {
    clientId: '',
    username: 'ART_Telemetry',
    password: mqtt_pw,
  });

  client.on('connect', () => {
    client.subscribe('#', { qos: 0 });
  });

  client.on('message', (topic, message) => {
    switch (topic) {
      case 'Throttle_position':
        Throttle_position.value = parseFloat(message.toString());
        break;
      case 'Front_brake_pressure':
        Front_brake_pressure.value = parseFloat(message.toString());
        break;
      case 'Steering_angle':
        Steering_angle.value = parseFloat(message.toString());
        break;
      case 'Battery_voltage':
        Battery_voltage.value = parseFloat(message.toString());
        break;
      case 'Cooleant_temp':
        Cooleant_temp.value = parseFloat(message.toString());
        break;
      case 'Oil_preassure':
        Oil_preassure.value = parseFloat(message.toString());
        break;
      case 'Pneu_pressure':
        Pneu_pressure.value = parseFloat(message.toString());
        break;
      case 'DRS':
        setDRS((message.toString()));
        break;
      case 'Gear':
        Gear.value = message.toString();
        break;
      case 'Vehicle_speed':
        Vehicle_speed.value = parseInt(message.toString());
        break;
      case 'RPM':
        RPM.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR1':
        Tyre_Temp_FR1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR3':
        Tyre_Temp_FR3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR5':
        Tyre_Temp_FR5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR7':
        Tyre_Temp_FR7.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL1':
        Tyre_Temp_FL1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL3':
        Tyre_Temp_FL3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL5':
        Tyre_Temp_FL5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL7':
        Tyre_Temp_FL7.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR1':
        Tyre_Temp_RR1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR3':
        Tyre_Temp_RR3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR5':
        Tyre_Temp_RR5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR7':
        Tyre_Temp_RR7.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL1':
        Tyre_Temp_RL1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL3':
        Tyre_Temp_RL3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL5':
        Tyre_Temp_RL5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL7':
        Tyre_Temp_RL7.value = parseFloat(message.toString());
        break;
      case 'brake_temp_fr':
        brake_temp_fr.value = parseFloat(message.toString());
        break;
      case 'brake_temp_rr':
        brake_temp_rr.value = parseFloat(message.toString());
        break;
      case 'Acceleration_right':
        Acceleration_right.value = parseFloat(message.toString());
        break;
      case 'Acceleration_forward':
        Acceleration_forward.value = parseFloat(message.toString());
        break;
      default:
        console.log('Unknown topic:', topic);
    };
  });
};

onMounted(() => {
  establishMqttConnection();
});
</script>

<template>
  <div class="tracktyre">
    <div class="tinnerl" :style="tempInnerLeftStyle" />
    <div class="tinnerr" :style="tempInnerrRightStyle" />
    <div class="tline left" :style="tempLeftStyle" />
    <div class="tline right"  :style="tempRightStyle" />
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  tempInnerLeft: {
    type: Number,
    required: true,
  },
  tempInnerRight: {
    type: Number,
    required: true,
  },
  tempLeft: {
    type: Number,
    required: true,
  },
  tempRight: {
    type: Number,
    required: true,
  },
});

const tempInnerLeftStyle= computed(() => {
  if ( props.tempInnerLeft > 0 && props.tempInnerLeft <= 25) {
    return {
      background: 'rgb(0, 21, 255)',
      opacity: '80%'
    };
  } else if ( props.tempInnerLeft > 25 && props.tempInnerLeft <= 35 ) {
    return {
      background: 'rgb(60, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.tempInnerLeft > 35 && props.tempInnerLeft <= 45 ) {
    return {
      background: 'rgb(255, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.tempInnerLeft > 45 && props.tempInnerLeft <= 55 ) {
    return {
      background: 'rgb(255, 145, 0)',
      opacity: '80%'
    };
  }else if ( props.tempInnerLeft > 55 && props.tempInnerLeft <= 65 ) {
    return {
      background: 'rgb(255, 0, 0)',
      opacity: '80%'
    };
  }else if ( props.tempInnerLeft > 65) {
    return {
      background: 'rgb(112, 0, 0)',
      opacity: '80%'
    };
  }
});


const tempLeftStyle= computed(() => {
  if (props.tempLeft > 0 && props.tempLeft < 25) {
    return {
      background: 'rgb(0, 21, 255)',
      opacity: '80%'
    };
  }else if ( props.tempLeft > 25 && props.tempLeft <= 35 ) {
    return {
      background: 'rgb(60, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.tempLeft > 35 && props.tempLeft <= 45 ) {
    return {
      background: 'rgb(255, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.tempLeft > 45 && props.tempLeft <= 55 ) {
    return {
      background: 'rgb(255, 145, 0)',
      opacity: '80%'
    };
  }else if ( props.tempLeft > 55 && props.tempLeft <= 65 ) {
    return {
      background: 'rgb(255, 0, 0)',
      opacity: '80%'
    };
  }else if ( props.tempLeft > 65) {
    return {
      background: 'rgb(112, 0, 0)',
      opacity: '80%'
    };
  }
});

const tempInnerrRightStyle = computed(() => {
  if (props.tempInnerRight > 0 && props.tempInnerRight < 25) {
    return {
      background: 'rgb(25, 0, 255)',
      opacity: '80%'
    };
  }else if ( props.tempInnerRight > 25 && props.tempInnerRight <= 35 ) {
    return {
      background: 'rgb(60, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.tempInnerRight > 35 && props.tempInnerRight <= 45 ) {
    return {
      background: 'rgb(255, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.tempInnerRight > 45 && props.tempInnerRight <= 55 ) {
    return {
      background: 'rgb(255, 145, 0)',
      opacity: '80%'
    };
  }else if ( props.tempInnerRight > 55 && props.tempInnerRight <= 65 ) {
    return {
      background: 'rgb(255, 0, 0)',
      opacity: '80%'
    };
  }else if ( props.tempInnerRight > 65) {
    return {
      background: 'rgb(112, 0, 0)',
      opacity: '80%'
    };
  }
});


const tempRightStyle = computed(() => {
  if (props.tempRight > 0 && props.tempRight < 25) {
    return {
      background: 'rgb(0, 21, 255)',
      opacity: '80%'
    };
  }else if ( props.tempRight > 25 && props.tempRight <= 35 ) {
    return {
      background: 'rgb(60, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.tempRight > 35 && props.tempRight <= 45 ) {
    return {
      background: 'rgb(255, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.tempRight > 45 && props.tempRight <= 55 ) {
    return {
      background: 'rgb(255, 145, 0)',
      opacity: '80%'
    };
  }else if ( props.tempRight > 55 && props.tempRight <= 65) {
    return {
      background: 'rgb(255, 0, 0)',
      opacity: '80%'
    };
  }else if ( props.tempRight > 65) {
    return {
      background: 'rgb(112, 0, 0)',
      opacity: '80%'
    };
  }
});

</script>

<template>
  <div class="track">
    <div class="tracktop">
      <div class="tracktopleft"><Steering_wheel :steering_angle="Steering_angle" /></div>
      <div class="tracktopcenter">Track</div>
      <div class="tracktopright">
        <Speedometer :speed="Vehicle_speed" :rpm="RPM" :gear="Gear" /></div>
      </div>
    <div class="trackbottom">
      <div class="trackbottomleft"><G-force  :XCoord="G_XCoord"  :YCoord="G_YCoord" /></div>
      <div class="trackbottomcenter">
        <div class="ttyretextl">
          <div class="tflt">
            Front left tyre
            <div class="tflts1">Sector1:  {{ fll_temp }} °C </div>
            <div class="tflts2">Sector2:  {{ flil_temp }} °C </div>
            <div class="tflts3">Sector3:  {{ flir_temp }} °C </div>
            <div class="tflts4">Sector4:  {{ flr_temp }} °C </div>
          </div>
          <div class="trlt">
            Rear left tyre
            <div class="trlts1">Sector1:  {{ rll_temp }} °C </div>
            <div class="trlts2">Sector2:  {{ rlil_temp }} °C </div>
            <div class="trlts3">Sector3:  {{ rlir_temp }} °C </div>
            <div class="trlts4">Sector4:  {{ rlr_temp }} °C </div>
          </div>
        </div>

        <div class="tyre-container">
          <div class="tyres">
            <TrackTyre
              class="tfl"
              :tempInnerLeft="flil_temp"
              :tempInnerRight="flir_temp"
              :tempLeft="fll_temp"
              :tempRight="flr_temp"
            />
            <TrackTyre
              class="tfr"
              :tempInnerLeft="fril_temp"
              :tempInnerRight="frir_temp"
              :tempLeft="frl_temp"
              :tempRight="frr_temp"
            />
          </div>

          <div class="tyres">
            <TrackTyre
              class="trl"
              :tempInnerLeft="rlil_temp"
              :tempInnerRight="rlir_temp"
              :tempLeft="rll_temp"
              :tempRight="rlr_temp"
            />
            <TrackTyre
              class="trr"
              :tempInnerLeft="rril_temp"
              :tempInnerRight="rrir_temp"
              :tempLeft="rrl_temp"
              :tempRight="rrr_temp"
            />
          </div>

          <DrsTrack class="twing" :class="{ open : DRS_ON, closed: DRS_OFF}"
            :drs_on="DRS_ON"
            :drs_off="DRS_OFF"
          />
        </div>
        <BrakeTempTrack class="tbrake_temp_car "
          :brakeTempFR="brake_temp_fr"
          :brakeTempRR="brake_temp_rr"
        />

        <div class="ttyretextr">
          <div class="tfrt">
            Front right tyre
            <div class="tfrts1">Sector1:  {{ frl_temp  }} °C </div>
            <div class="tfrts2">Sector2:  {{ fril_temp }} °C </div>
            <div class="tfrts3">Sector3:  {{ frir_temp }} °C </div>
            <div class="tfrts4">Sector4:  {{ frr_temp }} °C </div>
            <div class="brake_temp_t_fr">Brake temp: {{ brake_temp_fr }} °C</div>
          </div>
          <div class="trrt">
            Rear right tyre
            <div class="trrts1">Sector1:  {{ rrl_temp }} °C</div>
            <div class="trrts2">Sector2:  {{ rril_temp }} °C </div>
            <div class="trrts3">Sector3:  {{ rrir_temp  }} °C </div>
            <div class="trrts4">Sector4:  {{ rrr_temp }} °C </div>
            <div class="brake_temp_t_rr">Brake temp: {{ brake_temp_rr }} °C</div>
          </div>
        </div>
      </div>
      <div class="trackbottomright">
          <div class="tbr">
              <Pedals :th_ped_pos="Throttle_position" :br_ped_pos="Front_brake_pressure" />
          </div>
          <div class="icons">
            <div>
              <span class="battery_volts_t">{{ Battery_voltage }}</span>
              <img src="/img/battery.png" alt="Battery" class="battery" />
            </div>
            <div>
              <span class="oil_temp_t">{{ Oil_preassure }}</span>
              <img src="/img/oil.png" alt="Oil" class="oil" />
            </div>
            <div>
              <span class="water_temp_t">{{ Cooleant_temp }}</span>
              <img src="/img/water.png" alt="Water" class="water" />
            </div>
            <div>
              <span class="pneu_press_t">{{ Pneu_pressure }}</span>
              <img src="/img/pressure.png" alt="Pressure" class="pressure" />
            </div>
          </div>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Car from '@/components/Car.vue';
import Speedometer from '@/components/Speedometer.vue';
import GForce from '@/components/GForce.vue';
import BrakeTempTrack from '@/components/BrakeTempTrack.vue';
import BrakeTemp from '@/components/BrakeTemp.vue';
import Pedals from '@/components/Pedals.vue';
import Steering_wheel from '@/components/Steering_wheel.vue';
import mqtt from 'mqtt';
import TrackTyre from '@/components/TrackTyre.vue';
import DrsTrack from '@/components/DrsTrack.vue';

const mqtt_url = process.env.VUE_APP_MQTT_URL;
const mqtt_pw = process.env.VUE_APP_MQTT_PW;

const RPM = ref(0);
const Gear = ref('N');
const Vehicle_speed= ref(0);
const Throttle_position = ref(0);
const Front_brake_pressure = ref(0);
const Battery_voltage = ref(0);
const DRS = ref(null);
const DRS_ON = ref(false);
const DRS_OFF = ref(false);
const Cooleant_temp = ref(0);
const Oil_preassure = ref(0);
const Pneu_pressure = ref(0);
const brake_temp_fr =ref(0)
const brake_temp_rr =ref(0)

// Tyre temperatures
// --front right
const Tyre_Temp_FR1 = ref(0);
const Tyre_Temp_FR2  = ref(0);
const Tyre_Temp_FR3  = ref(0);
const Tyre_Temp_FR4  = ref(0);
const Tyre_Temp_FR5  = ref(0);
const Tyre_Temp_FR6  = ref(0);
const Tyre_Temp_FR7  = ref(0);
const Tyre_Temp_FR8  = ref(0);
// --front left
const Tyre_Temp_FL1 = ref(0);
const Tyre_Temp_FL2  = ref(0);
const Tyre_Temp_FL3  = ref(0);
const Tyre_Temp_FL4  = ref(0);
const Tyre_Temp_FL5  = ref(0);
const Tyre_Temp_FL6  = ref(0);
const Tyre_Temp_FL7  = ref(0);
const Tyre_Temp_FL8  = ref(0);
// --rear left
const Tyre_Temp_RL1 = ref(0);
const Tyre_Temp_RL2  = ref(0);
const Tyre_Temp_RL3  = ref(0);
const Tyre_Temp_RL4  = ref(0);
const Tyre_Temp_RL5  = ref(0);
const Tyre_Temp_RL6  = ref(0);
const Tyre_Temp_RL7  = ref(0);
const Tyre_Temp_RL8  = ref(0);
// --rear right
const Tyre_Temp_RR1 = ref(0);
const Tyre_Temp_RR2  = ref(0);
const Tyre_Temp_RR3  = ref(0);
const Tyre_Temp_RR4  = ref(0);
const Tyre_Temp_RR5  = ref(0);
const Tyre_Temp_RR6  = ref(0);
const Tyre_Temp_RR7  = ref(0);
const Tyre_Temp_RR8  = ref(0);

const Steering_angle = ref(0);
const G_XCoord = ref(0)
const G_YCoord = ref(0)

const setDRS = (drs_value) => {
  if(drs_value=="On"){
    drs_value=true
    if (drs_value == true) {
      DRS_ON.value = true;
      DRS_OFF.value = false;
    }
  }else if(drs_value=="Off"){
      DRS_ON.value = false;
      DRS_OFF.value = true;
  }
};

const establishMqttConnection = () => {
  const client = mqtt.connect(mqtt_url, {
    clientId: '',
    username: 'ART_Telemetry',
    password: mqtt_pw,
  });

  client.on('connect', () => {
    client.subscribe('#', { qos: 0 });
  });

  client.on('message', (topic, message) => {
    switch (topic) {
      case 'Throttle_position':
        Throttle_position.value = parseFloat(message.toString());
        break;
      case 'Front_brake_pressure':
        Front_brake_pressure.value = parseFloat(message.toString());
        break;
      case 'Steering_angle':
        Steering_angle.value = parseFloat(message.toString());
        break;
      case 'Battery_voltage':
        Battery_voltage.value = parseFloat(message.toString())/10;
        break;
      case 'Cooleant_temp':
        Cooleant_temp.value = parseFloat(message.toString())/10;
        break;
      case 'Oil_preassure':
        Oil_preassure.value = parseFloat(message.toString());
        break;
      case 'Pneu_pressure':
        Pneu_pressure.value = parseFloat(message.toString());
        break;
      case 'DRS':
        setDRS((message.toString()));
        break;
      case 'Gear':
        Gear.value = message.toString();
        break;
      case 'Vehicle_speed':
        Vehicle_speed.value = parseInt(message.toString());
        break;
      case 'RPM':
        RPM.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR1':
        Tyre_Temp_FR1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR3':
        Tyre_Temp_FR3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR5':
        Tyre_Temp_FR5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR7':
        Tyre_Temp_FR7.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL1':
        Tyre_Temp_FL1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL3':
        Tyre_Temp_FL3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL5':
        Tyre_Temp_FL5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL7':
        Tyre_Temp_FL7.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR1':
        Tyre_Temp_RR1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR3':
        Tyre_Temp_RR3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR5':
        Tyre_Temp_RR5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR7':
        Tyre_Temp_RR7.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL1':
        Tyre_Temp_RL1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL3':
        Tyre_Temp_RL3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL5':
        Tyre_Temp_RL5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL7':
        Tyre_Temp_RL7.value = parseFloat(message.toString());
        break;
      case 'brake_temp_fr':
        brake_temp_fr.value = parseFloat(message.toString());
        break;
      case 'brake_temp_rr':
        brake_temp_rr.value = parseFloat(message.toString());
        break;
      case 'G_XCoord':
        G_XCoord.value = parseFloat(message.toString());
        break;
      case 'G_YCoord':
        G_YCoord.value = parseFloat(message.toString());
        break;
      default:
        console.log('Unknown topic:', topic);
    };
  });
};

onMounted(() => {
  establishMqttConnection();
});
</script>

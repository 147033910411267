<template>
  <div class="chart battery_voltage_chart">
    <apexchart
      :height="150"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script setup>

import { ref, watch, defineProps, onMounted } from 'vue';

const props = defineProps({
  battery_voltage: Number,
});

const series = ref([
  {
    name: 'Battery_voltage',
    data: [],
  },
]);

const xaxisRange = ref(30000);

const chartOptions = ref({
  chart: {
    id: 'Battery voltage-chart',
    type: 'line',
    height: 150,
    animations: {
      enabled: false,
      easing: 'linear',
      dynamicAnimation: {
        speed: 1000,
      },
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
    },
  },
  theme: {
    mode: 'dark',
    palette: 'palette4',

  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
  },
  title: {
    text: 'Battery voltage',
    align: 'left',
  },
  markers: {
    size: 0,
  },
  xaxis: {
    type: 'datetime',
    range: xaxisRange,
    
    labels: {
      formatter: (value) => {
        const date = new Date(value);
        return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      },
    }
  },
});

watch(() => props.battery_voltage, (newValue) => {
  series.value[0].data.push({
    x: new Date().getTime(),
    y: newValue,
  });
  if (series.value[0].data.length > 31) {
    series.value[0].data.shift();
  }
});

</script>
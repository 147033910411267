<template>
    <div class="brake_temp">
        <div class="brake_fr"  :style="BrakeTempFRStyle" />
        <div class="brake_rr"  :style="BrakeTempRRStyle" />
    </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  brakeTempFR: {
    type: Number,
    required: true,
  },
  brakeTempRR: {
    type: Number,
    required: true,
  }
});


const BrakeTempFRStyle= computed(() => {
  if ( props.brakeTempFR > 0 && props.brakeTempFR <= 80) {
    return {
      background: 'rgb(0, 21, 255)',
      opacity: '80%'
    };
  } else if ( props.brakeTempFR > 80 && props.brakeTempFR <= 140 ) {
    return {
      background: 'rgb(60, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.brakeTempFR > 140 && props.brakeTempFR <= 200) {
    return {
      background: 'rgb(255, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.brakeTempFR > 200 && props.brakeTempFR <= 280 ) {
    return {
      background: 'rgb(255, 145, 0)',
      opacity: '80%'
    };
  }else if ( props.brakeTempFR > 280) {
    return {
      background: 'rgb(255, 0, 0)',
      opacity: '80%'
    };
  }
});


const BrakeTempRRStyle= computed(() => {
  if ( props.brakeTempRR > 0 && props.brakeTempRR <= 80) {
    return {
      background: 'rgb(0, 21, 255)',
      opacity: '80%'
    };
  } else if ( props.brakeTempRR > 80 && props.brakeTempRR <= 140 ) {
    return {
      background: 'rgb(60, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.brakeTempRR > 140 && props.brakeTempRR <= 200 ) {
    return {
      background: 'rgb(255, 255, 0)',
      opacity: '80%'
    };
  }else if ( props.brakeTempRR > 200 && props.brakeTempRR <= 280 ) {
    return {
      background: 'rgb(255, 145, 0)',
      opacity: '80%'
    };
  }else if ( props.brakeTempRR > 280) {
    return {
      background: 'rgb(255, 0, 0)',
      opacity: '80%'
    };
  }
});

</script>


<template>
  <div class="pedcontainer">
    <div class="brpedtext">Brake</div>
    <div class="brake" :style="brStyle"></div>
    <div class="br2pedtext">{{ br_ped_pos }} %</div>
    <div class="thpedtext">Throttle</div>
    <div class="throttle" :style="thStyle"></div>
    <div class="th2pedtext">{{ th_ped_pos }} %</div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
const props = defineProps({
  th_ped_pos: {
    type: Number,
    required: true,
  },
  br_ped_pos: {
    type: Number,
    required: true,
  },
});

const brStyle = computed(() => {
  return {
    background: `linear-gradient(to top, rgb(128, 0, 0) ${props.br_ped_pos}%, transparent 0%)`,
    animation: "ease"
  };
});

const thStyle = computed(() => {
  return {
    background: `linear-gradient(to top, rgb(0, 120, 0) ${props.th_ped_pos}%, transparent 0%)`,
    animation: "ease"
  };
});
</script>

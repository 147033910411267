<template>
    <div class="diagcont">
        <GearChart :gear="Gear"/>
    </div>
    <div class="diagcont">
        <RPMChart :rpm="RPM"/>
    </div>
    <div class="diagcont">
       <SteeringAngleChart :steering_angle="Steering_angle"/>
    </div>
    <div class="diagcont">
      <ThrottlePositionChart :th_ped_pos="Throttle_position"/>
    </div>
    <div class="diagcont">
      <BrakePositionChart :br_ped_pos="Front_brake_pressure"/>
    </div>
    <div class="diagcont">
      <CoolantTempChart :coolant_temp="Cooleant_temp"/>
    </div>
    <div class="diagcont">
      <BatteryVoltageChart :battery_voltage="Battery_voltage"/>
    </div>
    <div class="diagcont">
      <OilPressureChart :oil_pressure="Oil_pressure"/>
    </div>
</template>

<script setup>
import GearChart from '../components/Charts/GearChart.vue';
import RPMChart from '../components/Charts/RPMChart.vue';
import SteeringAngleChart from'../components/Charts/SteeringAngleChart.vue';
import ThrottlePositionChart   from'../components/Charts/ThrottlePositionChart.vue';
import BrakePositionChart   from'../components/Charts/BrakePositionChart.vue';
import CoolantTempChart   from'../components/Charts/CoolantTempChart.vue';
import BatteryVoltageChart   from'../components/Charts/BatteryVoltageChart.vue';
import OilPressureChart   from'../components/Charts/OilPressureChart.vue';
import { ref, onMounted } from 'vue';
import mqtt from 'mqtt';
import { set } from 'date-fns';



const mqtt_url = process.env.VUE_APP_MQTT_URL;
const mqtt_pw = process.env.VUE_APP_MQTT_PW;

const RPM = ref(0);
const Gear = ref('N');
const Wheel_speed_RL= ref(0);
const Throttle_position = ref(0);
const Front_brake_pressure = ref(0);
const Battery_voltage = ref(0);
const DRS = ref(null);
const DRS_ON = ref(false);
const DRS_OFF = ref(false);
const Cooleant_temp = ref(0);
const Oil_pressure = ref(0);
const pneu_pressure = ref(0);
const brake_temp_fr =ref(0)
const brake_temp_rr =ref(0)

// Tyre temperatures
// --front right
const Tyre_Temp_FR1 = ref(0);
const Tyre_Temp_FR2  = ref(0);
const Tyre_Temp_FR3  = ref(0);
const Tyre_Temp_FR4  = ref(0);
const Tyre_Temp_FR5  = ref(0);
const Tyre_Temp_FR6  = ref(0);
const Tyre_Temp_FR7  = ref(0);
const Tyre_Temp_FR8  = ref(0);
// --front left
const Tyre_Temp_FL1 = ref(0);
const Tyre_Temp_FL2  = ref(0);
const Tyre_Temp_FL3  = ref(0);
const Tyre_Temp_FL4  = ref(0);
const Tyre_Temp_FL5  = ref(0);
const Tyre_Temp_FL6  = ref(0);
const Tyre_Temp_FL7  = ref(0);
const Tyre_Temp_FL8  = ref(0);
// --rear left
const Tyre_Temp_RL1 = ref(0);
const Tyre_Temp_RL2  = ref(0);
const Tyre_Temp_RL3  = ref(0);
const Tyre_Temp_RL4  = ref(0);
const Tyre_Temp_RL5  = ref(0);
const Tyre_Temp_RL6  = ref(0);
const Tyre_Temp_RL7  = ref(0);
const Tyre_Temp_RL8  = ref(0);
// --rear right
const Tyre_Temp_RR1 = ref(0);
const Tyre_Temp_RR2  = ref(0);
const Tyre_Temp_RR3  = ref(0);
const Tyre_Temp_RR4  = ref(0);
const Tyre_Temp_RR5  = ref(0);
const Tyre_Temp_RR6  = ref(0);
const Tyre_Temp_RR7  = ref(0);
const Tyre_Temp_RR8  = ref(0);

const Steering_angle = ref(0);
const G_XCoord = ref(0)
const G_YCoord = ref(0)

const setDRS = (drs_value) => {
  if(drs_value=="On"){
    drs_value=true
    if (drs_value == true) {
      DRS_ON.value = true;
      DRS_OFF.value = false;
    }
  }else if(drs_value=="Off"){
      DRS_ON.value = false;
      DRS_OFF.value = true;
  }
};

const establishMqttConnection = () => {
  const client = mqtt.connect(mqtt_url, {
    clientId: '',
    username: 'ART_Telemetry',
    password: mqtt_pw,
  });

  client.on('connect', () => {
    client.subscribe('#', { qos: 0 });
  });

  client.on('message', (topic, message) => {
    switch (topic) {
      case 'Throttle_position':
        Throttle_position.value = parseFloat(message.toString());
        break;
      case 'Front_brake_pressure':
        Front_brake_pressure.value = parseFloat(message.toString());
        break;
      case 'Steering_angle':
        Steering_angle.value = parseFloat(message.toString());
        break;
      case 'Battery_voltage':
        Battery_voltage.value = parseFloat(message.toString());
        break;
      case 'Cooleant_temp':
        Cooleant_temp.value = parseFloat(message.toString());
        break;
      case 'Oil_pressure':
        Oil_pressure.value = parseFloat(message.toString());
        break;
      case 'pneu_pressure':
        pneu_pressure.value = parseFloat(message.toString());
        break;
      case 'DRS':
        setDRS((message.toString()));
        break;
      case 'Gear':
        Gear.value = message.toString();
        break;
      case 'Wheel_speed_RL':
        Wheel_speed_RL.value = parseInt(message.toString());
        break;
      case 'RPM':
        RPM.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR1':
        Tyre_Temp_FR1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR3':
        Tyre_Temp_FR3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR5':
        Tyre_Temp_FR5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FR7':
        Tyre_Temp_FR7.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL1':
        Tyre_Temp_FL1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL3':
        Tyre_Temp_FL3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL5':
        Tyre_Temp_FL5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_FL7':
        Tyre_Temp_FL7.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR1':
        Tyre_Temp_RR1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR3':
        Tyre_Temp_RR3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR5':
        Tyre_Temp_RR5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RR7':
        Tyre_Temp_RR7.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL1':
        Tyre_Temp_RL1.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL3':
        Tyre_Temp_RL3.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL5':
        Tyre_Temp_RL5.value = parseFloat(message.toString());
        break;
      case 'Tyre_Temp_RL7':
        Tyre_Temp_RL7.value = parseFloat(message.toString());
        break;
      case 'brake_temp_fr':
        brake_temp_fr.value = parseFloat(message.toString());
        break;
      case 'brake_temp_rr':
        brake_temp_rr.value = parseFloat(message.toString());
        break;
      case 'G_XCoord':
        G_XCoord.value = parseFloat(message.toString());
        break;
      case 'G_YCoord':
        G_YCoord.value = parseFloat(message.toString());
        break;
      default:
        console.log('Unknown topic:', topic);
    };
  });
};

onMounted(() => {
  establishMqttConnection();
});
</script>

<template>
  <div class="Oil pressure-chart">
    <apexchart
      :height="150"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, onMounted } from 'vue';

const props = defineProps({
  oil_press: String,
});

const series = ref([
  {
    name: 'Oil pressure',
    data: [],
  },
]);

const xaxisRange = ref(30000);

const chartOptions = ref({
  chart: {
    id: 'Oil pressure-chart',
    type: 'line',
    height: 150,
    animations: {
      enabled: false,
      easing: 'linear',
      dynamicAnimation: {
        speed: 1000,
      },
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
    },
  },
  theme: {
    mode: 'dark',
    palette: 'palette6',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'stepline',
  },
  title: {
    text: 'Oil pressure',
    align: 'left',
  },
  markers: {
    size: 0,
  },
  xaxis: {
    type: 'datetime',
    range: xaxisRange,
    
    labels: {
      formatter: (value) => {
        const date = new Date(value);
        return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      },
    }
  },
  yaxis: {
    max: 5,
    min: 0,
  },
});

const gearToNumber = (gear) => (gear === 'N' ? 0 : Number(gear));


watch(() => props.oil_press, (newValue) => {
  series.value[0].data.push({
    x: new Date().getTime(),
    y: newValue,
  });
  if (series.value[0].data.length > 31) {
    series.value[0].data.shift();
  }
});

</script>

<template>
  <div class="steering-wheel-container">
    <img
      src="/img/Steering_wheel.png"
      alt="Steering Wheel"
      class="steering-wheel"
      :style="steeringAngleStyle"
    />
    <div class="steering_angle_text">{{ steering_angle }}°</div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
const props = defineProps({
  steering_angle: {
    type: Number,
    required: true,
  },
});

const steeringAngleStyle = computed(() => {
  return {
    transform: `rotate(${props.steering_angle}deg)`,
  };
});
</script>

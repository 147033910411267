<template>
  <div :style="backgroundStyle" class="login-container">
    <div class="login-box">
      <h1>Arrabona Racing Team Telemetry - Login</h1>
      <div class="input-group">
        <label for="username">Username</label>
        <input type="text" id="username" v-model="username" />
      </div>
      <div class="input-group">
        <label for="password">Password</label>
        <input type="password" id="password" v-model="password" />
      </div>
      <button type="submit" @click="login">Login</button>
      <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      backgroundStyle: {},
      errorMessage: '',
    };
  },
  methods: {
    getRandomBackground() {
      const images = [
        '/img/ART_Telemetry_loginbg.png',
        '/img/ART_Telemetry_loginbg1.png',
        '/img/ART_Telemetry_loginbg2.png'
      ];
      const randomImage = images[Math.floor(Math.random() * images.length)];
      return `url('${randomImage}') no-repeat center center fixed`;
    },
    login() {
      const correctUsername = process.env.VUE_APP_LOGIN_UN;
      const correctPassword = process.env.VUE_APP_LOGIN_PW;

      console.log('correctUsername', correctUsername);

      if (
        this.username === correctUsername &&
        this.password === correctPassword
      ) {
        localStorage.setItem('loggedIn', true);
        this.$router.push({ name: 'Dashboard' });
      } else {
        this.errorMessage = 'Wrong username or password';
        this.password = '';
        this.username = '';
      }
    },
  },
  
  mounted() {
    this.backgroundStyle = {
      background: this.getRandomBackground(),
      backgroundSize: 'cover',
    };
  },
};
</script>

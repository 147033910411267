import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '@/views/DashboardView.vue';
import TrackView from '@/views/TrackView.vue';
import DiagramView from '@/views/DiagramView.vue';
import LoginView from '@/views/Login.vue';

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
  },

  {
    path: '/track',
    name: 'Track',
    component: TrackView,
  },

  {
    path: '/diagram',
    name: 'Diagram',
    component: DiagramView,
  },

  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to, from, next) => {
  const publicRouteNames = ['Login'];
  const loggedIn = localStorage.getItem("loggedIn");
  
 if (!publicRouteNames.includes(to.name) && !loggedIn) {
    return next({ name: 'Login' });
  }
  
  if (!routes.some((route) => route.name === to.name)) {
    next({ name: 'Dashboard' });
  } else {
    next();
  }
});

export default router;
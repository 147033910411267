<template>
  <div class="chart gearchart">
    <apexchart
      :height="150"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, onMounted } from 'vue';

const props = defineProps({
  gear: String,
});

const series = ref([
  {
    name: 'Gear',
    data: [],
  },
]);

const xaxisRange = ref(30000);

const chartOptions = ref({
  chart: {
    id: 'gear-chart',
    type: 'line',
    height: 150,
    animations: {
      enabled: false,
      easing: 'linear',
      dynamicAnimation: {
        speed: 1000,
      },
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: true,
    },
  },
  theme: {
    mode: 'dark',
    palette: 'palette1',
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'stepline',
  },
  title: {
    text: 'Gear',
    align: 'left',
  },
  markers: {
    size: 0,
  },
  xaxis: {
    type: 'datetime',
    range: xaxisRange,
    
    labels: {
      formatter: (value) => {
        const date = new Date(value);
        return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      },
    }
  },
  yaxis: {
    max: 5,
    min: 0,
  },
});

const gearToNumber = (gear) => (gear === 'N' ? 0 : Number(gear));


watch(
  () => props.gear,
  (newVal) => {
    const x = Date.now();
    const y = gearToNumber(newVal);
    series.value[0].data.push({ x, y });

    if (series.value[0].data.length > 10) {
      series.value[0].data.shift();
    }

    chartOptions.value.xaxis.max = Math.max(
      ...series.value[0].data.map((d) => d.x)
    );
    chartOptions.value.xaxis.min = chartOptions.value.xaxis.max - xaxisRange;
  }
);
</script>

<template>
  <div class="speedometer">
    <div class="circle">
      <div class="speedometer-scale"></div>
      <div class="scaleline"></div>
      <div class="scalenum1">0</div>
      <div class="scaleline05"></div>
      <div class="scaleline1"></div>
      <div class="scalenum2">10</div>
      <div class="scaleline15"></div>
      <div class="scaleline2"></div>
      <div class="scalenum3">20</div>
      <div class="scaleline25"></div>
      <div class="scaleline3"></div>
      <div class="scalenum4">30</div>
      <div class="scaleline35"></div>
      <div class="scaleline4"></div>
      <div class="scalenum5">40</div>
      <div class="scaleline45"></div>
      <div class="scaleline5"></div>
      <div class="scalenum6">50</div>
      <div class="scaleline55"></div>
      <div class="scaleline6"></div>
      <div class="scalenum7">60</div>
      <div class="scaleline65"></div>
      <div class="scaleline7"></div>
      <div class="scalenum8">70</div>
      <div class="scaleline75"></div>
      <div class="scaleline8"></div>
      <div class="scalenum9">80</div>
      <div class="scaleline85"></div>
      <div class="scalenum10">90</div>
      <div class="scalenum11">100</div>
      <div class="scalenum12">110</div>
      <div class="scalenum13">120</div>
      <div class="covercircle"></div>
      <div class="speedscale"></div>
      <div class="coverrectangle"></div>
      <div class="geart">{{ gear }}</div>
      <div class="rpmt">{{ rpm }} rpm</div>
      <div class="speedt">{{ speed }} km/h</div>
      <div class="rpm-indicator" :style="RPMStyle" ></div>
    </div>
    <canvas ref="speedometerCanvas" width="330px" height="330px"></canvas>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, computed } from 'vue';

const props = defineProps({
  speed: {
    type: Number,
    required: true,
  },
  gear: {
    type: Number,
    required: true,
  },
  rpm: {
    type: Number,
    required: true,
  }
});


const RPMStyle= computed(() => {
  var upperlimit=11000
  var percantage
  var modulus
  if(upperlimit<=props.rpm){
    upperlimit=props.rpm
    modulus=upperlimit/100
    percantage=props.rpm/modulus
    return {
    background: `linear-gradient(to right, rgb(48, 179, 126) ${percantage}%, transparent 0%)`,
    animation: "ease"
    }
  }else{
    modulus=upperlimit/100
    percantage=props.rpm/modulus
    return {
    background: `linear-gradient(to right, rgb(48, 179, 126) ${percantage}%, transparent 0%)`,
    animation: "ease"
  }
  }
})



const speedometerCanvas = ref(null);

onMounted(() => {
  drawSpeedometer();
});

watch(() => props.speed, (value) => {
  drawSpeedometer(value);
},
{
  immediate:true
});



function drawSpeedometer(value = props.speed) {
  const canvas = speedometerCanvas.value;
  console.log(canvas)
  if (!canvas) return;
  

  const ctx = canvas.getContext('2d');

  const centerX = canvas.width / 2;
  const centerY = canvas.height / 2;
  const radius = canvas.width / 2 - 10;

  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Draw gauge value
  const startAngle = Math.PI * 0.75;
  const endAngle = (value / 120) * (Math.PI * 1.5) + startAngle;;
  ctx.beginPath();
  ctx.arc(centerX, centerY, radius, startAngle, endAngle);
  ctx.lineWidth = 7;
  ctx.strokeStyle = getColor(value);
  ctx.stroke();
  ctx.closePath();
}

function getColor(value) {
  if (0 < value) {
      return '#cf0e00';
  }
}
</script>

<style scoped>
.speedometer {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>



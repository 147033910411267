<template>
  <Header v-if="showHeader" />
  <router-view />
</template>

<script setup>
import Header from './components/Header.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const showHeader = computed(() => route.name !== 'Login');

</script>
